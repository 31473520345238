"use client";

import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isVisible?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { isVisible, ...restProps } = props;

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "3rem";
      if (props.value) {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      } else {
        textareaRef.current.style.height = "3rem";
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [props.value]);

  useEffect(() => {
    if (isVisible && textareaRef.current) {
      let attempts = 0;
      const focusInterval = setInterval(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
        attempts++;
        if (attempts >= 5) clearInterval(focusInterval);
      }, 500);

      return () => clearInterval(focusInterval);
    }
  }, [isVisible]);

  useImperativeHandle(ref, () => textareaRef.current!);

  return (
    <textarea
      ref={textareaRef}
      {...restProps}
      className={`w-full resize-none overflow-hidden rounded-lg border-none text-gray-900 ring-offset-0 focus:ring-0 focus:ring-offset-0 focus:ring-offset-0 md:text-xs ${props.className}`}
      style={{
        height: "3rem",
        lineHeight: "normal",
        padding: "0.25rem",
      }}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e);
        }
        adjustHeight();
      }}
    />
  );
});
