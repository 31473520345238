"use client";
import { cn } from "@repo/utils";
import { useEffect, useState } from "react";
import SmallLoadingSpinner from "./smallLoadingSpinner";

interface Interaction {
  inner_reasoning: string;
  working_knowledge: { question: string; answer: string }[];
  working_memory: { content: string; timestamp: string; context: string }[];
}

interface InteractionTabsProps {
  interaction: Interaction | null;
  isStreamingMsg: boolean;
  isLastInteractionCompleted: boolean;
  isLoadingInteraction: boolean;
}

export function InteractionTabs({
  interaction,
  isStreamingMsg,
  isLastInteractionCompleted,
  isLoadingInteraction,
}: InteractionTabsProps) {
  const [tabsData, setTabsData] = useState<{
    [key: string]: { id: string | number; title: string; content: string; detail?: string }[];
  } | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("");

  useEffect(() => {
    if (interaction) {
      const formattedData = {
        "Inner Reasoning": [
          {
            id: "inner_reasoning", // Unique ID for key prop
            title: "Inner Reasoning Overview",
            content: interaction.inner_reasoning,
          },
        ],
        "Working Knowledge": interaction.working_knowledge.map((item, index) => ({
          id: index,
          title: item.question,
          content: item.answer,
        })),
        "Working Memory": interaction.working_memory.map((memory, index) => ({
          id: index,
          title: `Event ${index + 1}`,
          content: memory.content,
          detail: `Timestamp: ${memory.timestamp}, Context: ${memory.context}`,
        })),
      };
      setTabsData(formattedData);
      setSelectedTab(Object.keys(formattedData)[0] ?? ""); // Set default selected tab
    }
  }, [interaction]);

  if (!tabsData || isStreamingMsg || !isLastInteractionCompleted || isLoadingInteraction) {
    return (
      <div className="flex h-full w-full items-center justify-center align-middle">
        <SmallLoadingSpinner size={40} />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full rounded-md sm:px-0">
      <div className="flex w-full flex-col">
        <div className="flex w-full">
          <select
            className="flex w-full rounded-full border-indigo-300 bg-indigo-200 py-1.5 text-xs font-medium leading-5 focus:outline-none focus:ring-2"
            value={selectedTab}
            onChange={(e) => setSelectedTab(e.target.value ?? "")}
          >
            {Object.keys(tabsData).map((tab) => (
              <option key={tab} value={tab}>
                {tab}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-2 flex h-full max-w-[400px] rounded-md bg-white pb-10">
          {Object.entries(tabsData).map(([category, items]) =>
            category === selectedTab ? (
              <div
                key={category}
                className={cn(
                  "relative h-full overflow-y-auto rounded-xl pb-10 ring-white ring-offset-2 ring-offset-blue-400 focus:outline-none",
                )}
              >
                <ul>
                  {items.map(({ id, title, content, detail }) => (
                    <li key={id} className="relative rounded-md p-3 hover:bg-gray-100">
                      <h3 className="text-sm font-medium leading-5">{title}</h3>
                      <p className="mt-1 text-xs font-normal leading-4 text-gray-500">{content}</p>
                      {detail && <p className="mt-2 text-xs font-normal leading-4 text-gray-500">{detail}</p>}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null,
          )}
        </div>
      </div>
    </div>
  );
}
