"use client";

import { Button } from "./button";

interface DataTableRowActionsProps {
  actions: { label: string; onClick: (row: any) => void }[];
}

export const DataTableRowActions = ({ actions }: DataTableRowActionsProps) => {
  return (
    <div className="flex space-x-2">
      {actions?.map((action, index) => (
        <Button key={index} onClick={action.onClick} className="truncate">
          {action.label}
        </Button>
      ))}
    </div>
  );
};
